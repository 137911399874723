import React, { useState, useEffect } from "react";
import {PortfolioGallery} from "../../components";

import {images} from "../../constants";

import { AppWrap } from "../../wrapper";
import "./Work.scss";

const Work = () => {
  const [works, setWorks] = useState([]);
  const [filterWork, setFilterWork] = useState([]);
  const [activeFilter, setActiveFilter] = useState("All");

  useEffect(() => {
    var data = [
      {
        caption: "Yoga \u2022 2018 \u2022 Silverpoint with white watercolor on toned gesso \u2022 19'' x 23''",
        tags:["Recent Silverpoints"],
        src: images.yoga,
        width:4,
        height:3,
        orientation:'landscape'
      },
      {
        caption: "Study For St. Sebastion of The Disco Ball \u2022 2020 \u2022 Silverpoint on panel \u2022 20'' x 16''",
        tags:["Recent Silverpoints"],
        src: images.sebastion_disco,
        width: 3,
        height: 4,
        orientation:'portrait'
      },
      {
        caption: "The Bath \u2022 2022 \u2022 Oil on linen \u2022 40'' x 30''",
        tags: ["Paintings"],
        src: images.bath,
        width: 4,
        height: 5,
        orientation:'portrait'
      },
      {
        caption: "Bayside View \u2022 2017 \u2022 Oil on linen \u2022 40'' x 30''",
        tags:["Paintings"],
        src: images.bayside,
        width:3,
        height:4,
        orientation:'portrait'
      },
      {
        caption: "Culebra Seascape \u2022 2019 \u2022 Silverpoint on panel \u2022 24'' x 12''",
        tags:["Recent Silverpoints"],
        src: images.culebra,
        width: 1,
        height: 2,
        orientation:'portrait'
      },
      {
        caption: "Amanda and Clara \u2022 2020 \u2022 Pen and ink over red chalk on paper \u2022 14'' x 10''",
        tags: ["Portraits"],
        src: images.amanda,
        width: 5,
        height: 7,
        orientation:'portait'
      },
      {
        caption: "Poki \u2022 2018 \u2022 Pen and ink over redchalk on paper \u2022 14'' x 10''",
        tags: ["Portraits"],
        src: images.poki,
        width: 5,
        height: 7,
        orientation:'portrait'
      },
      {
        caption: "Still Life \u2022 2022 \u2022 Silverpoint with egg tempera on panel \u2022 24'' x 12''",
        tags: ["Recent Silverpoints",'Paintings'],
        src: images.stationary,
        width: 1,
        height: 2,
        orientation:'portrait'
      },
      {
        caption: "Havesting Sea Urchin \u2022 2019 \u2022 Oil on linen \u2022 30'' x 40''",
        tags: ["Paintings"],
        src: images.seaurchin,
        width: 4,
        height: 3,
        orientation:'landscape'
      },
      {
        caption: "Eason \u2022 2018 \u2022 Pen and ink over red chalk on paper \u2022 14'' x 10''",
        tags: ["Portraits"],
        src: images.eason,
        width: 5,
        height: 7,
        orientation:'portrait'
      },
      {
        caption: "Stan \u2022 2016 \u2022 Pen and ink over red chalk on paper \u2022 14'' x 10''",
        tags: ["Portraits"],
        src: images.stan,
        width: 5,
        height: 7,
        orientation:'portait'
      },
      {
        caption: "Shooting Bow \u2022 2013 \u2022 Silverpoint with white watercolor on toned gesso \u2022 12” x 16”",
        tags:["Early Silverpoints"],
        src: images.shooting_bow,
        width:6,
        height:5,
        orientation:'landscape'
      },
      {
        caption: "Janu Sirsasana \u2022 2013 \u2022 Silverpoint with white watercolor on toned gesso  \u2022 12'' x 16''",
        tags:["Early Silverpoints"],
        src: images.janu_sirsasana,
        width: 5,
        height: 4,
        orientation:'landscape'
      },
      {
        caption: "Uttanasana \u2022 2013 \u2022 Silverpoint with white watercolor on toned gesso \u2022 23'' x 18''",
        tags: ["Early Silverpoints"],
        src: images.uttanasana,
        width: 18,
        height: 23,
        orientation:'portrait'
      },
      {
        caption: "Parighasana \u2022 2013 \u2022 Silverpoint with white watercolor on toned gesso \u2022 18'' x 23''",
        tags:["Early Silverpoints"],
        src: images.parighasana,
        width: 23,
        height:18,
        orientation:'landscape'
      },
      {
        caption: "Scorpian \u2022 2013 \u2022 Silverpoint with white watercolor on toned gesso \u2022 16'' x 12''",
        tags:["Early Silverpoints"],
        src: images.scorpian,
        width: 3,
        height: 4,
        orientation:'portrait'
      },
      {
        caption: "Dancing Lord Siva \u2022 2013 \u2022 Silverpoint with white watercolor on toned gesso \u2022 23'' x 17''",
        tags: ["Early Silverpoints"],
        src: images.dancing_lord_siva,
        width: 17,
        height: 23,
        orientation:'portrait'
      },
      {
        caption: "Icarus \u2022 2013 \u2022 Silverpoint with white watercolor on toned gesso \u2022 12'' x 16''",
        tags: ["Early Silverpoints"],
        src: images.icarus,
        width: 5,
        height: 4,
        orientation:'landscape'
      },
      {
        caption: "Untitled \u2022 2008  Silverpoint with white watercolor on toned gesso \u2022 14'' x 16''",
        tags: ["Early Silverpoints"],
        src: images.untitled,
        width: 8,
        height: 7,
        orientation:'landscape'
      },
      {
        caption: "Untitled \u2022 2009 Silverpoint with white watercolor on toned gesso \u2022 13'' x 16''",
        tags: ["Early Silverpoints"],
        src: images.untitled2,
        width: 16,
        height: 13,
        orientation:'landscape'
      },
      {
        caption: "Caryatid \u2022 2008 \u2022 Silverpoint with white watercolor on toned gesso \u2022 16'' x 13''",
        tags:["Early Silverpoints"],
        src: images.caryatid,
        width: 13,
        height: 16,
        orientation:'portrait'
      },
      {
        caption: "Current State of Affairs \u2022 2008 \u2022 Silverpoint with white watercolor on toned gesso \u2022 7'' x 16''",
        tags: ["Early Silverpoints"],
        src: images.curr_st_aff,
        width: 16,
        height: 7,
        orientation:'landscape'
      },
      {
        caption: "Meditation \u2022 2008 \u2022 Silverpoint with white watercolor on toned gesso \u2022 13'' x 15''",
        tags:["Early Silverpoints"],
        src: images.meditation,
        width:15,
        height:13,
        orientation:'landscape'
      },
      {
        caption: "Rope \u2022 2009 \u2022 Egg Tempera on panel \u2022 16'' x 10'' ",
        tags:["Paintings"],
        src: images.rope,
        width: 5,
        height: 8,
        orientation:'portrait'
      },
      {
        caption: "Imp \u2022 2008 - 2012 \u2022 Egg Tempera on panel \u2022 7.5'' x 16''",
        tags: ["Paintings"],
        src: images.imp,
        width: 32,
        height: 15,
        orientation:'landscape'
      },
      {
        caption: "A.-3x \u2022 2008 \u2022 Egg Tempera on panel \u2022 7.5'' x 5.25'' each",
        tags: ["Paintings"],
        src: images.a3x,
        width: 7,
        height: 3,
        orientation:'landscape'
      },
      {
        caption: "Balance \u2022 2008 \u2022 Egg Tempera on Panel \u2022 13'' x 16''",
        tags: ["Paintings"],
        src: images.balance,
        width: 16,
        height: 13,
        orientation:'landscape'
      },
      {
        caption: "Man In Sky \u2022 2007 \u2022 Oil on panel \u2022 32'' x 42''",
        tags: ["Paintings"],
        src: images.man_in_sky,
        width: 21,
        height: 16,
        orientation:'landscape'
      },
      {
        caption: "Study For Man In Sky IV \u2022 2006 \u2022 Oil on canvas \u2022 18'' x 24''",
        tags: ["Paintings"],
        src: images.study_man_in_sky_4,
        width: 4,
        height: 3,
        orientation:'landscape'
      },
      {
        caption: "Figure Study, Gabe \u2022 2005 \u2022 Oil on panel \u2022 12'' x 9''",
        tags: ["Paintings"],
        src: images.figure_study_gabe,
        width: 3,
        height: 4,
        orientation:'portrait'
      },
      {
        caption: "If Modigliani Was Gay \u2022 2005 \u2022 Oil on canvas \u2022 24'' x 36''",
        tags: ["Paintings"],
        src: images.if_modigliani_was_gay,
        width: 3,
        height: 2,
        orientation:'landscape'
      },
      {
        caption: "At The Northumberland \u2022 2001 \u2022 Egg Tempera on panel \u2022 12'' x 18''",
        tags: ["Paintings"],
        src: images.at_northumberland,
        width: 3,
        height: 2,
        orientation:'landscape'
      },
      {
        caption: "Luganis Gold \u2022 1998 \u2022 Egg Tempera on panel \u2022 12'' x 12''",
        tags: ["Paintings"],
        src: images.luganis_gold,
        width: 1,
        height: 1,
        orientation:'landscape'
      },
      {
        caption: "Tom \u2022 2014 \u2022 Pen and ink over red chalk on paper \u2022 13'' x 9''",
        tags: ["Portraits"],
        src: images.tom,
        width: 3,
        height: 4,
        orientation:'portrait'
      },
      {
        caption: "Portraitist's Portrait \u2022 2013 \u2022 Pen and ink over red chalk on paper \u2022 12'' x 10''",
        tags: ["Portraits"],
        src: images.portraitist_portrait,
        width: 5,
        height: 7,
        orientation:'portrait'
      },
      {
        caption: "Mike \u2022 2013 \u2022 Pen and ink over red chalk on paper \u2022 12'' x 10''",
        tags: ["Portraits"],
        src: images.mike,
        width: 5,
        height: 7,
        orientation:'portrait'
      },
      {
        caption: "Frederick \u2022 2013 Pen and ink over red chalk on paper \u2022 12'' x 10''",
        tags: ["Portraits"],
        src: images.frederick,
        width: 5,
        height: 7,
        orientation:'portrait'
      },
      {
        caption: "Self \u2022 2013 \u2022 Pen and ink over red chalk on paper \u2022 11'' x 9''",
        tags: ["Portraits"],
        src: images.self,
        width: 9,
        height: 11,
        orientation:'portrait'
      },
      {
        caption: "Bernard \u2022 2012 \u2022 Pen and ink over red chalk on paper \u2022 11'' x 9''",
        tags: ["Portraits"],
        src: images.bernard,
        width: 9,
        height: 11,
        orientation:'portrait'
      },
      {
        caption: "Ann \u2022  2012 \u2022 Pen and ink over red chalk on paper \u2022 12'' x 10''",
        tags: ["Portraits"],
        src: images.ann,
        width: 11,
        height: 14,
        orientation:'portrait'
      },
      {
        caption: "Rope \u2022 2004 \u2022 Silverpoint with white watercolor on toned gesso \u2022 16'' x 12'' ",
        tags: ["Early Silverpoints"],
        src: images.brian_rope,
        width: 3,
        height: 4,
        orientation:'portrait'
      },
      {
        caption: "Out Of The Darkness \u2022 2008 \u2022 Silverpoint with white watercolor on toned gesso \u2022 13.5'' x 16.5''",
        tags: ["Early Silverpoints"],
        src: images.out_of_darkness,
        width: 11,
        height: 9,
        orientation:'landscape'
      },
      {
        caption: "Vargas Guy \u2022 2005 \u2022 Silverpoint with white watercolor on toned gesso \u2022 16'' x 12''",
        tags: ["Early Silverpoints"],
        src: images.vargas,
        width: 3,
        height: 4,
        orientation:'portrait'
      },
      {
        caption: "Gabe As Landscape \u2022 2005 \u2022 Silverpoint with white watercolor on toned gesso \u2022 8'' x 16''",
        tags: ["Early Silverpoints"],
        src: images.gabe_landscape,
        width: 2,
        height: 1,
        orientation:'landscape'
      },
      {
        caption: "Spinning \u2022 2005 \u2022 Silverpoint with white watercolor on toned gesso \u2022 14'' x 14''",
        tags: ["Early Silverpoints"],
        src: images.spinning,
        width: 1,
        height: 1,
        orientation:'portrait'
      },
      {
        caption: "Thigh High Boots, Fish Tattoo \u2022 2004 \u2022 Silverpoint with white watercolor on toned gesso \u2022 16'' x 6''",
        tags: ["Early Silverpoints"],
        src: images.boots,
        width: 3,
        height: 8,
        orientation:'portrait'
      },
      {
        caption: "Pin-Up \u2022 2004 \u2022 Silverpoint with white watercolor on toned gesso \u2022 10'' x 7''",
        tags: ["Early Silverpoints"],
        src: images.pinup,
        width: 3,
        height: 8,
        orientation:'portrait'
      },
      {
        caption: "Homage To Paul Cadmus \u2022 2004 \u2022 Silverpoint with white watercolor on toned gesso \u2022 17'' x 14''",
        tags: ["Early Silverpoints"],
        src: images.homage,
        width: 14,
        height: 17,
        orientation:'portrait'
      },
      {
        caption: "Gray-Green (Alan) \u2022 2002 \u2022 Silverpoint with white watercolor on toned gesso \u2022 14'' x 11''",
        tags: ["Early Silverpoints"],
        src: images.alan,
        width: 11,
        height: 14,
        orientation:'portrait'
      },
      {
        caption: "Fuschia (Gabe) \u2022 2002 \u2022 Silverpoint with white watercolor on toned gesso \u2022 14'' x 11''",
        tags: ["Early Silverpoints"],
        src: images.fuschia_gabe,
        width: 11,
        height: 14,
        orientation:'portrait'
      },
      {
        caption: "Aqua (Hero) \u2022 2002 \u2022 Silverpoint with white watercolor on toned gesso \u2022 14'' x 11''",
        tags: ["Early Silverpoints"],
        src: images.aqua_hero,
        width: 11,
        height: 14,
        orientation:'portrait'
      },
      {
        caption: "Blue (Terry) \u2022 2002 \u2022 Silverpoint with white watercolor on toned gesso \u2022 9'' x 11.5''",
        tags: ["Early Silverpoints"],
        src: images.blue_terry,
        width: 23,
        height: 18,
        orientation:'landscape'
      },
      {
        caption: "Stolen From Abercrombie And Fitch \u2022 2001 \u2022 Silverpoint with white watercolor on toned gesso \u2022 11.5'' x 9''",
        tags: ["Early Silverpoints"],
        src: images.af,
        width: 18,
        height: 23,
        orientation:'portrait'
      },
      {
        caption: "Lavender-Blue (Irving) \u2022 2001 \u2022 Silverpoint with white watercolor on toned gesso \u2022 9'' x 11.5''",
        tags: ["Early Silverpoints"],
        src: images.irving,
        width: 23,
        height: 18,
        orientation:'landscape'
      },
      {
        caption: "Cadmus Study \u2022 2002 \u2022 Silverpoint with white watercolor on toned gesso \u2022 9'' x 11.5''",
        tags: ["Early Silverpoints"],
        src: images.cadmus_study,
        width: 17,
        height: 10,
        orientation:'landscape'
      },
      {
        caption: "Alan \u2022 2004 \u2022 Pen and ink on paper \u2022 8'' x 13''",
        tags: ["Related Works"],
        src: images.pen_ink_alan,
        width: 13,
        height: 8,
        orientation:'landscape'
      },
      {
        caption: "Study For Gabe As A Landscape \u2022 2004 \u2022 Pen and ink on paper \u2022 6.5'' x 13.5''",
        tags: ["Related Works"],
        src: images.pen_ink_gabe,
        width: 27,
        height: 13,
        orientation:'landscape'
      },
      {
        caption: "Gabe From Behind \u2022 2004 \u2022 Pen and ink on paper \u2022 13.5'' x 7''",
        tags: ["Related Works"],
        src: images.gabe_from_behind,
        width: 14,
        height: 27,
        orientation:'portrait'
      },
      {
        caption: "Seated Female \u2022 2001 \u2022 Silverpoint with white watercolor on toned gesso \u2022 11.5'' x 9''",
        tags: ["Related Works"],
        src: images.seated_female,
        width: 14,
        height: 21,
        orientation:'portrait'
      },
      {
        caption: "Reclining Female \u2022 2001 \u2022 Pen and ink on paper \u2022 8'' x 12''",
        tags: ["Related Works"],
        src: images.reclining_female,
        width: 4,
        height: 3,
        orientation:'landscape'
      },
      {
        caption: "Art And Fashion: Calvin Klein Underware Box \u2022 1998 \u2022 Carved Marble Relief with Black Paint and Presstype \u2022 7.5'' x 3.75'' x 1.25''",
        tags: ["Related Works"],
        src: images.ck,
        width: 3,
        height: 4,
        orientation:'portrait'
      }
    ];

    setWorks(data);
    setFilterWork(data);
  }, []);

  const handleWorkFilter = (item) => {
    setActiveFilter(item);
    if (item === "All") {
      setFilterWork(works);
    } else {
      const x = works.filter((work) => work.tags.includes(item));
      console.log(x)
      setFilterWork(x);
    }
  };

  return (
    <>
      <div className="app__work-filter">
        {/* {["UI/UX", "Web App", "Mobile App", "React JS", "All"].map( */}
        {["All", "Early Silverpoints", "Paintings", "Portraits",
          "Recent Silverpoints", "Related Works"].map((item, index) => (
          <div
            key={index}
            onClick={() => handleWorkFilter(item)}
            className={`app__work-filter-item app__flex p-text ${
              activeFilter === item ? "item-active" : ""
            }`}
          >
            {item}
          </div>
        ))}
      </div>

      <div className="gallery-wrapper">
        <PortfolioGallery photos={filterWork}
        />
      </div>
    </>
  );
};

export default AppWrap(
  Work,
  "portfolio",
  "app__primarybg"
);
