import logo from "../assets/logo.png";
import email from "../assets/email.png";

import stationary from "../assets/22/Stationary.jpg";
import seaurchin from "../assets/22/seaurchin.jpg";
import yoga from "../assets/22/yoga.jpg";
import sebastion_disco from "../assets/22/sebastion disco.jpg"
import bath from "../assets/22/The Bath.jpg"
import bayside from "../assets/22/Bayside view.jpg"
import culebra from "../assets/22/Culebra sea scape.jpg"
import poki from "../assets/22/Poki1.jpg"
import eason from "../assets/22/eason.jpg"
import amanda from "../assets/22/amanda.jpg"
import stan from "../assets/22/stan.jpg"


import shooting_bow from "../assets/05/shooting_bow.jpg"
import janu_sirsasana from "../assets/05/janu_sirsasana.jpg"
import uttanasana from "../assets/05/uttanasana.jpg"
import parighasana from "../assets/05/parighasana.jpg"
import scorpian from "../assets/05/scorpian.jpg"
import dancing_lord_siva from "../assets/05/dancing_lord_siva.jpg"
import icarus from "../assets/05/Icarus.jpg"
import untitled from "../assets/05/frontpage2-e.jpg"
import untitled2 from "../assets/05/Yoga.jpg"
import caryatid from "../assets/05/caryatid.jpg"
import curr_st_aff from "../assets/05/current_state_of_affairs.jpg"
import meditation from "../assets/05/meditation.jpg"
import rope from "../assets/05/Rope.jpg"
import imp from "../assets/05/imp.jpg"
import a3x from "../assets/05/A3x.jpg"
import balance from "../assets/05/balance.jpg"
import man_in_sky from "../assets/05/man_in_sky.jpg"
import study_man_in_sky_4 from "../assets/05/study_man_in_sky_4.jpg"
import figure_study_gabe from "../assets/05/figure_study_gabe.jpg"
import if_modigliani_was_gay from "../assets/05/If_modigliani_was_gay.jpg"
import at_northumberland from "../assets/05/at_northumberland.jpg"
import luganis_gold from "../assets/05/luganis_gold.jpg"
import tom from "../assets/04/tom.jpg"
import portraitist_portrait from "../assets/05/sean.jpg"
import mike from "../assets/05/mike.jpg"
import frederick from "../assets/05/frederick.jpg"
import self from "../assets/05/self.jpg"
import bernard from "../assets/05/bernard.jpg"
import ann from "../assets/04/ann.jpg"
import brian_rope from "../assets/05/brian_rope.jpg"
import out_of_darkness from "../assets/05/out_of_darkness.jpg"
import vargas from "../assets/05/vargas.jpg"
import gabe_landscape from "../assets/05/gabe_landscape.jpg"
import spinning from "../assets/05/spinning.jpg"
import boots from "../assets/05/boots.jpg"
import pinup from "../assets/05/pinup.jpg"
import homage from "../assets/05/homage.jpg"
import alan from "../assets/05/alan.jpg"
import fuschia_gabe from "../assets/05/fuschia_gabe.jpg"
import aqua_hero from "../assets/05/aqua_hero.jpg"
import blue_terry from "../assets/05/blue_terry.jpg"
import af from "../assets/05/af.jpg"
import irving from "../assets/05/irving.jpg"
import cadmus_study from "../assets/05/cadmus_study.jpg"
import pen_ink_alan from "../assets/05/pen_ink_alan.jpg"
import pen_ink_gabe from "../assets/05/pen_ink_gabe.jpg"
import gabe_from_behind from "../assets/05/gabe_from_behind.jpg"
import seated_female from "../assets/05/seated_female.jpg"
import reclining_female from "../assets/05/reclining_female.jpg"
import ck from "../assets/05/ck.jpg"

const images = {
  logo,
  email,
  
  stationary,
  seaurchin,
  yoga,
  sebastion_disco,
  bath,
  bayside,
  culebra,
  amanda,
  poki,
  eason,
  stan,  

  shooting_bow,
  janu_sirsasana,
  uttanasana,
  parighasana,
  scorpian,
  dancing_lord_siva,
  icarus,
  untitled,
  untitled2,
  caryatid,
  curr_st_aff,
  meditation,
  rope,
  imp,
  a3x,
  balance,
  man_in_sky,
  study_man_in_sky_4,
  figure_study_gabe,
  if_modigliani_was_gay,
  at_northumberland,
  luganis_gold,
  tom,
  portraitist_portrait,
  mike,
  frederick,
  self,
  bernard,
  ann,
  brian_rope,
  out_of_darkness,
  vargas,
  gabe_landscape,
  spinning,
  boots,
  pinup,
  homage,
  alan,
  fuschia_gabe,
  aqua_hero,
  blue_terry,
  af,
  irving,
  cadmus_study,
  pen_ink_alan,
  pen_ink_gabe,
  gabe_from_behind,
  seated_female,
  reclining_female,
  ck
};

export default images;
