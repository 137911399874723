import React from "react";
import { motion } from "framer-motion";

import { AppWrap } from "../../wrapper";
import { images } from "../../constants";
import "./Header.scss";

const Header = () => (
  <div className="app__header app__flex">
    <motion.div
      whileInView={{ x: [-100, 0], opacity: [0, 1] }}
      transition={{ duration: 0.5 }}
      className="app__header-info"
    >
      <div className="app__header-badge">
        <div className="caption" >
          {'\u02FB'}Harvesting Sea Urchin{'\u02FA'}
        </div>
        <div className="method">
          <p>2019</p>
          <p>Oil on  Canvas</p>
          <p>30'' x 40''</p>
        </div>
      </div>
    </motion.div>

    <motion.div
      whileInView={{ opacity: [0, 1] }}
      transition={{ duration: 0.5, delayChildren: 0.5 }}
      className="app__header-img"
    >
      <img src={images.seaurchin} alt="profile_bg" className="landing" />
    </motion.div>
  </div>
);

export default AppWrap(Header, "home");
