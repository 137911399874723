import React from "react";

import { images } from "../../constants";
import { AppWrap, MotionWrap } from "../../wrapper";
import "./Footer.scss";

const Footer = () => {

  return (
    <>
      <h2 className="head-text">
        <h6 className="smaller">Many of these works are available for purchase</h6><br/>
        Contact Me
      </h2>

      <div className="app__footer-cards">
        <div className="app__footer-card ">
          <img src={images.email} alt="email" />
          <a href="mailto:artdan9@gmail.com" className="p-text">
            artdan9@gmail.com
          </a>
        </div>
      </div>
    </>
  );
};

export default AppWrap(
  MotionWrap(Footer, "app__footer"),
  "contact",
  "app__whitebg"
);
