import React, { useState, useEffect, Fragment } from "react";
import { motion } from "framer-motion";

import { AppWrap, MotionWrap } from "../../wrapper";
import "./About.scss";

const About = () => {
  
  const [abouts, setAbouts] = useState([]);
  const [filterAbout, setFilterAbout] = useState([]);
  const [activeFilter, setActiveFilter] = useState("The Artist");
  const [animateCard, setAnimateCard] = useState({ y: 0, opacity: 1 });

  useEffect(() => {

    const data = [
      {
        tags:"The Artist",
        content: `<p>I began studying the figure classically, through old master techniques, such as egg tempera painting, clay and stone sculpture, or silver point and sepia ink drawings. I experimented with abstract welded steel, but I always had a fascination with Renaissance artists, especially Dürer and Michaelangelo. Although I have worked in more contemporary media, it is the older materials and techniques that have sustained my interest. Like the American Artists Paul Cadmus, and Thomas Eakins before him, I have an appreciation for the male figure. I haven’t fully pursued this subject to exhaustion, and I am still challenged and inspired by the human form.</p>
                  <ul>
                    <li>Born in Detroit Michigan, moved to the Washington DC area in 1968.</li>
                    <li>1974-1979 Studied old master drawing and painting techniques with Allan Marsh, and sculpture with Orest Poliszczuk at Montgomery College, Rockville Md.</li>
                    <li>1979-1980: Apprentice to Lila Katzen, Sculptor, New York City.</li>
                    <li>1981: Studied painting with Gene Davis at the Corcoran School of Art.</li>
                    <li>1982: Commissioned by the City of Rockville to produce a permanent outdoor sculpture titled Family III for Wooton’s Mill Park.</li>
                    <li>1985: One man sculpture show at Foxley Leech Gallery, Georgetown.</li>
                    <li>1984-1998: Worked at the Hirshhorn Museum and Sculpture Garden, Smithsonian Institution, in the Office of Exhibits and Design.</li>
                    <li>1999-2004: Studied drawing, anatomy, painting and sculpture with Robert Liberace.</li>
                    <li>1998-Present: self employed Master Gardner</li>
                    <li>10/2019: One Man Show - (<i>Mostly</i>) <i>Male</i>  &nbsp; at Allesse Art Gallery
                  </ul>`
      },
      {
        tags:"Egg Tempera Painting",
        content: `<p>Egg tempera is merely pure pigment mixed with egg yolk and water. When dry the egg becomes crystal clear and the pigment appears luminescent. Light travels through the suspended pigment, reflecting off the polished white ground, passing back through the tempera paint. Egg tempera tends to be applied through small brush strokes painstakingly layered to produce the desired result.</p>
                  <p>Egg tempera painting is recognized as the second oldest medium after encaustic. It was used by the ancient Egyptians and Greeks and perfected by the icon painters during the last 100 years of the old Byzantine Empire (400 AD-1202 AD).</p>
                  <p>After the fall of the Byzantine Empire, egg tempera flourished for about 200 years in the hands of the early Renaissance artists. Egg tempera has seen a revival at the turn of the 19th century and still has an interest amongst some artists today.</p>
                  <p>20th century masters of the medium include Paul Cadmus, George Tooker, and Jared French.</p>`
      },
      {
        tags: "Silverpoint Drawing",
        content: `<p>Silverpoint pre-dates graphite as a drawing medium. Its use is associated largely with the Renaissance and with old masters such as Jan Van Eyck, Leonardo da Vinci, Albrecht Dürer, Raphael, and as late as Rembrandt.</p>
                  <p>During the 15th and 16th centuries, the surface was prepared with a ground composed of powdered bones and gumwater, giving it an ivory finish – although in Italy, and especially in Florence, tinted grounds were favored.</p>
                  <p>Silverpoint (or metal point) had numerous advantages as a medium and a technique. Renaissance artists used silver and occasionally leadpoint for preparatory sketches under their paintings and frescoes as well as for studies on paper. Silverpoint carried the advantage that it did not interact with the paint – especially important when egg tempera was employed. And, of course, it had the advantage of being completely contained in a single device; no supplementary solutions or materials were needed for the silverpoint application.</p>
                  <p>These same characteristics allowed silverpoint to be used in several applications outside of the world of art. Its fixity and ease made it the perfect device for quick handwritten records or calculations that could later be “erased” with a new ground. “Their surfaces, usually made from a mixture of gesso and glue but sometimes from black bitumen, allowed merchants, writers, musicians, artists and travelers of all kinds to jot down notes with the use of a metal stylus when it would have been difficult or impossible to use pen and ink.” [1]</p>
                  <p>“More suited to permanent drawing is the silverpoint, which requires special preparation of the foundation and, once applied, cannot be corrected. Its stroke, also pale gray, oxidizes into brown and adheres unerasably. Silverpoint drawings accordingly require a clearer concept of form and a steady hand because corrections remain visible. Because too much pressure can bring about cracks in the foundation, the strokes must be even; emphases, modelling, and light phenomena must be rendered either by means of dense hachures, repetitions, and blanks or else supplemented by other mediums.</p>
                  <p>Despite these difficulties, silverpoint was much used in the 15th and 16th centuries. Dürer’s notebook on a journey to Holland shows landscapes, portraits, and various objects that interested him drawn in this demanding technique. Silverpoint was much in demand for portrait drawings from the 15th into the 17th century; and revived in the 18th-century Romantic era.” [2]</p>
                  <p>But despite its obvious advantages, silverpoint was surpassed by graphite by the 18th century. Although there were occasional revivals of use in the 19th century, it fell to the side as a common technique. There are a few modern works (the 1920 portrait of Marcel Duchamp by Joseph Stella in MOMA) that employ the technique. It is still occasionally used by modern artists.</p>
                  <p>[1] – Peter Stallybrass</p>
                  <p>[2] – Encyclopedia Britanica</p>
                  <p>For additional information see:</p>
                  <p><a href="http://www.folger.edu/pr_preview.cfm?prid=187&amp;is_archived=1" target="_blank">http://www.folger.edu/pr_preview.cfm?prid=187&amp;is_archived=1</a><br>
                  <a href="http://www.silverpointweb.com/overview.html" target="_blank">http://www.silverpointweb.com/overview.html</a><br>
                  <a href="http://www.phf.upenn.edu/Images/writingsurfaces.pdf" target="_blank">http://www.phf.upenn.edu/Images/writingsurfaces.pdf</a><br>
                  <a href="http://en.wikipedia.org/wiki/Silverpoint" target="_blank">http://en.wikipedia.org/wiki/Silverpoint</a><br>
                  <a href="http://www.britannica.com/EBchecked/topic/544951/silverpoint" target="_blank">http://www.britannica.com/EBchecked/topic/544951/silverpoint</a></p>
                  <p><em>A special thanks to Mark Dimunation for his help in compiling this information.</em></p>`
      }
    ];
    setAbouts(data);
    setFilterAbout(data.filter((about) => about.tags.includes("The Artist")));
  }, []);

  const handleAboutFilter = (item) => {
    setActiveFilter(item);
    setAnimateCard([{ y: 100, opacity: 0 }]);

    setTimeout(() => {
      setAnimateCard([{ y: 0, opacity: 1 }]);

      setFilterAbout(abouts.filter((about) => about.tags.includes(item)));
    }, 500);
  };

  return (
    <>
      <h2 className="head-text">
        <strong>About</strong>
      </h2>

      <div className="app__about-filter">
        {/* {["UI/UX", "Web App", "Mobile App", "React JS", "All"].map( */}
        {["The Artist", "Egg Tempera Painting", "Silverpoint Drawing"].map((item, index) => (
          <div
            key={index}
            onClick={() => handleAboutFilter(item)}
            className={`app__about-filter-item app__flex p-text ${
              activeFilter === item ? "item-active" : ""
            }`}
          >
            {item}
          </div>
        ))}
      </div>         

      <motion.div
        animate={animateCard}
        transition={{ duration: 0.5, delayChildren: 0.5 }}
        className="app__about-item"
      >
        {filterAbout.map((about, index) => (
          <div dangerouslySetInnerHTML={{ __html:about.content }}>
          </div>
        ))}
      </motion.div>
    </>
  );
};

export default AppWrap(
  MotionWrap(About, "app__about"),
  "about",
  "app__whitebg"
);
